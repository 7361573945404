import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import * as Sections from 'components/sections';
import logo from 'assets/img/logo-white.svg';
import characters from 'assets/img/characters.svg';
import charYellow from 'assets/img/char-yellow.svg';
import callout from 'assets/img/quote-callout.svg';

function App() {
	const goToElement = (elId, offset = 0) => {
		const doc = document.documentElement;
		const currentTop = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

		const element = document.getElementById(elId);
		const elementPosition = element.getBoundingClientRect().top;
		
		const offsetPosition = currentTop + elementPosition + offset;	
		window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
	}

	return (
		<div className="studycle overflow-x-hidden">
			<Sections.Banner 
				onRegister={() => { goToElement('registration', 0) }}
			/>

			<Sections.Intro />
			<Sections.Program 
				onRegister={() => { goToElement('registration', 0) }}
			/>
			<Sections.Steam />

			<Sections.Material />
			<Sections.Schedule />

			<section id="contact" className="w-full pt-20 pb-12 px-4 md:px-12 flex flex-col items-center">
				<div className="relative rounded-3xl bg-ls-pink py-8 px-4 md:px-12 ml-16 md:ml-0">
					<img src={callout} alt="" className="absolute -left-8 -bottom-4 h-16" />
					<img src={charYellow} alt="" className="absolute -left-32 -bottom-8 md:-left-64 md:-bottom-1/2 h-32 md:h-64" />
					<h3 className="text-ls-pink-dark mb-4">Ada Pertanyaan Seputar Little Studycle?</h3>
					<p>Untuk pertanyaan atau enquiries lainnya dapat menghubungi</p>
					<ul className="relative mt-8">
						<li className="mb-2 flex">
							<span className="mr-2"><FontAwesomeIcon icon={faWhatsapp}/></span>
							<a href="https://wa.me/628164246780?text=Saya ingin bertanya tentang Online Class Studycle Kids" target="_blank" rel="noreferrer noopener">wa.me/628164246780</a>
						</li>
						<li className="mb-2">
							<span className="mr-2"><FontAwesomeIcon icon={faEnvelope}/></span>
							<a href="mailto:kids@studycle.com?subject=Info Online Class" target="_blank" rel="noreferrer noopener">kids@studycle.com</a>
						</li>
						<li className="mb-2">
							<span className="mr-2"><FontAwesomeIcon icon={faPhoneAlt}/></span>
							<a href="tel:+622150986181" target="_blank" rel="noreferrer noopener">021-50986181</a>
						</li>
					</ul>
				</div>
			</section>

			<Sections.Form />

			<footer className="w-full bg-ls-pink">
				<div className="relative flex flex-col justify-center items-center text-white p-12">
					<img src={characters} alt="Studycle Logo White" className="h-52 absolute -top-36 md:-top-40" />
					<img src={logo} alt="Studycle Logo White" className="h-14 mt-16 mb-8" />
					<p className="font-semibold text-center">
						Little Studycle hadir untuk menjadi rekan dalam mendampingi Moms and Dads dan anak-anak mengeksplorasi dunia pendidikan.
					</p>

					<hr className="w-full my-8"/>
					<ul className="flex">
						<li onClick={() => { goToElement('about', -30) }} className="cursor-pointer hover:underline mx-2">Tentang</li>
						<li onClick={() => { goToElement('program') }} className="cursor-pointer hover:underline mx-2">Program</li>
						<li onClick={() => { goToElement('contact') }} className="cursor-pointer hover:underline mx-2">Kontak</li>
						<li onClick={() => { goToElement('registration') }} className="cursor-pointer hover:underline mx-2">Pendaftaran</li>
					</ul>
				</div>
			</footer>
		</div>
		);
	}
	
	export default App;
	
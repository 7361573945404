import girl from 'assets/img/little-girl.jpg';
import quote from 'assets/img/quote.svg';

const SectionIntro = () => {
	return (
			<section className="w-full flex flex-col justify-center items-center">
				<div className="p-8 md:px-0 md:py-20 md:flex md:justify-center">
					<div className="bg-ls-pink lg:mx-8 md:flex md:max-w-3xl rounded-3xl">
						<div>
							<div className="h-96 md:w-96 bg-center md:bg-bottom bg-cover rounded-3xl lg:h-full" 
								style={{ backgroundImage: `url('${girl}')` }}>
							</div>
						</div>
						<div className="flex flex-col py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2">
							<img className="h-8 md:h-10 self-center" src={quote} alt="Quote" />
							<p className="text-lg mt-4 text-gray-800 font-thin tracking-wide leading-relaxed">Usia 3-6 tahun adalah periode vital pada anak-anak untuk mendapatkan pendidikan berupa pengalaman langsung dengan lingkungan sekitar dan juga stimulasi untuk merangsang pertumbuhan dan perkembangan mereka.</p>
						</div>
					</div>
				</div> 
			</section>
    );
}

export default SectionIntro;
	
import logo from 'assets/img/logo.svg';
import logoWhite from 'assets/img/logo-white.svg';
import heroBanner from 'assets/img/hero-bg.jpg';
import hero from 'assets/img/hero.png';
import donut from 'assets/img/donut.png';
import square from 'assets/img/square.png';
import triangle from 'assets/img/triangle.png';
import monkey from 'assets/img/monkey.png';

const SectionBanner = ({ onRegister, ...props }) => {
    return (
        <section className="flex flex-col min-w-screen min-h-screen">
            <div className="relative flex-1 bg-cover bg-center overflow-hidden" style={{ backgroundImage: `url('${ heroBanner }')` }}>
                <div className="hero-banner absolute inset-0 h-full flex flex-col" style={{ background: "rgba(255, 206, 73, 0.75)"}}>
                    <div className="pt-8 px-10">
                        <img className="max-h-10" src={logoWhite} alt="Studycle Logo" />
                    </div>
                    
                    <div className="container mx-auto h-full flex">
                        <div className="hidden md:flex md:absolute top-2 w-1/3 h-full bg-contain bg-bottom bg-no-repeat" style={{ backgroundImage: `url('${ hero }')` }} />
                        <div className="hidden md:flex md:w-1/3" />
                        <div className="relative z-10 flex flex-1 flex-col items-center md:items-start h-full px-8 pt-12 lg:pt-20 text-center md:text-left">
                            <h2 className="text-5xl md:text-6xl mb-2 text-white leading-tight" style={{ textShadow: "0px 3px 15px rgba(174, 40, 8, 0.25)" }}>
                                Online Class<br/>for Your Kids
                            </h2>
                            <p className="text-lg md:text-xl md:mt-4 mb-8 text-gray-900">
                                Belajar itu mudah dan menyenangkan bersama Little Studycle
                            </p>
                            <button onClick={onRegister} className="btn-pill white uppercase">
                                Daftar Sekarang
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div id="about" className="relative w-full flex flex-col justify-center items-center mt-4 mb-8 md:mb-4 px-4">
                <img src={monkey} alt="" className="hidden md:block absolute -right-8 lg:-right-16 -top-32 lg:-top-48 h-48 lg:h-64" />

                <img className="h-16 md:h-18 mb-4 md:mb-8"
                    src={logo} alt="Little Studycle" />

                <p className="relative text-xl md:max-w-3xl text-center font-semibold leading-relaxed">
                    <img src={donut} alt="" className="hidden md:block absolute -left-32 lg:-left-72 -top-28 lg:-top-40 h-28 lg:h-40" />
                    <img src={square} alt="" className="hidden md:block absolute -right-32 lg:-right-72 -bottom-28 lg:-bottom-38 h-24 lg:h-32" />
                    <img src={triangle} alt="" className="hidden md:block absolute -left-28 lg:-left-72 -bottom-48 lg:-bottom-64 h-16 lg:h-24" />

                    Little Studycle hadir untuk menjadi rekan Moms and Dads dalam mendampingi buah hati mengeksplorasi dunia pendidikan.
                </p>
            </div>
        </section>
    );
}

export default SectionBanner;
	
import selfLearning from "assets/img/self-learn.png";
import onlineClass from "assets/img/online-class.png";

const SectionProgram = ({ onRegister }) => {
	return (
        <section id="program" className="flex flex-col items-center px-5 py-5 w-full">
            <div className="flex items-center justify-center mb-8">
                <h2 className="text-5xl text-ls-blue-dark">
                    Program Kami
                </h2>
                <button onClick={onRegister} className="hidden md:block btn-pill blue py-2 px-4 ml-8">Daftar Sekarang</button>
            </div>
            <p className="md:max-w-4xl text-center px-4 md:px-8 text-lg tracking-wide leading-relaxed">
                Memadukan pendidikan berbasis aktivitas tematik dengan cakupan bidang perkembangan anak yang komprehensif seperti nilai moral, sosial emosional, kognitif, linguistik, motorik, dan seni budaya, Little Studycle menawarkan dua program yang dapat dipilih sesuai kebutuhan Moms and Dads:
            </p>
            <div className="w-full p-2 md:px-0 md:py-10 flex flex-wrap md:flex-nowrap justify-center">
                <div 
                    className="w-full rounded-3xl shadow-xl overflow-hidden relative m-4 px-8 pt-16 pb-32 bg-cover bg-no-repeat bg-center text-white h-96" 
                    style={{ 
                        maxWidth: "400px",
                        backgroundImage: `url('${selfLearning}')`
                }}>
                    <h3 className="text-3xl font-semibold text-white leading-tight mb-10 relative z-10">
                        Self-Learning
                    </h3>
                </div>

                <div className="list-info pb-8 ml-8 md:hidden">
                    <h1 className="text-2xl text-ls-blue-dark tracking-wide mb-2">Self-Learning</h1>
                    <p className="leading-relaxed mb-2">
                        Program belajar mandiri ini mencakup learning kit yang akan dikirimkan setiap bulannya untuk dipakai sebagai penunjang aktivitas pembelajaran mandiri anak. Learning kit yang dikirimkan dilengkapi juga dengan panduan untuk Moms and Dads agar proses belajar di rumah bisa berlangsung dengan baik dan menarik.
                    </p>
                </div>

                <div 
                    className="w-full rounded-3xl shadow-xl overflow-hidden relative m-4 px-8 pt-16 pb-32 bg-cover bg-no-repeat bg-center text-white h-96" 
                    style={{ 
                        maxWidth: "400px",
                        backgroundImage: `url('${onlineClass}')`
                }}>
                    <h3 className="text-3xl font-semibold text-white leading-tight mb-10 relative z-10">
                        Online Class
                    </h3>

                </div>

                <div className="list-info pb-8 ml-8 md:hidden">
                    <h1 className="text-2xl text-ls-blue-dark tracking-wide mb-2">Online Class</h1>
                    <p className="leading-relaxed mb-2">
                        Diprogram selama 30 menit setiap hari Senin, Rabu dan Jumat, kegiatan dalam kelas online akan bervariasi yang tidak terbatas pada aktivitas bersama seperti  storytelling, sing and dance along, tetapi juga aktivitas individu dengan panduan berupa  art and craft, pembelajaran materi dengan worksheet, busy book dan lain-lain.
                        Sama seperti program Self-Learning, peserta program Online Class juga akan mendapatkan learning kit yang akan dikirimkan setiap bulannya.
                    </p>
                    <p className="leading-relaxed mb-2">
                        Kelas online Little Studycle akan dimulai pada 1 February 2021 dan diadakan selama 4 bulan (kelas terakhir akan berlangsung pada 21 Mei 2021).
                    </p>
                </div>
            </div> 
            <div className="hidden md:flex justify-center w-full mt-8">
                <ul className="w-full px-16 lg:w-2/3 text-lg">
                    <li className="list-info pb-8">
                        <h1 className="text-2xl text-ls-blue-dark tracking-wide mb-2">Self-Learning</h1>
                        <p className="leading-relaxed mb-2">
                            Program belajar mandiri ini mencakup learning kit yang akan dikirimkan setiap bulannya untuk dipakai sebagai penunjang aktivitas pembelajaran mandiri anak. Learning kit yang dikirimkan dilengkapi juga dengan panduan untuk Moms and Dads agar proses belajar di rumah bisa berlangsung dengan baik dan menarik.
                        </p>
                    </li>
                    <li className="list-info pb-8">
                        <h1 className="text-2xl text-ls-blue-dark tracking-wide mb-2">Online Class</h1>
                        <p className="leading-relaxed mb-2">
                            Diprogram selama 30 menit setiap hari Senin, Rabu dan Jumat, kegiatan dalam kelas online akan bervariasi yang tidak terbatas pada aktivitas bersama seperti  storytelling, sing and dance along, tetapi juga aktivitas individu dengan panduan berupa  art and craft, pembelajaran materi dengan worksheet, busy book dan lain-lain.
                            Sama seperti program Self-Learning, peserta program Online Class juga akan mendapatkan learning kit yang akan dikirimkan setiap bulannya.
                        </p>
                        <p className="leading-relaxed mb-2">
                            Kelas online Little Studycle akan dimulai pada 1 February 2021 dan diadakan selama 4 bulan (kelas terakhir akan berlangsung pada 21 Mei 2021)
                        </p>
                    </li>
                </ul>
            </div>
        </section>
		);
	}

export default SectionProgram;
	
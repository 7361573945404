import learningMaterial from "assets/img/learning-material.jpg";

const SectionMaterial = () => {
	return (
        <section className="flex flex-col items-center px-5 py-5 w-full mt-16">
            <div className="flex items-center mb-8">
                <h2 className="text-5xl text-ls-blue-dark text-center">
                    Materi Pembelajaran
                </h2>
            </div>
            <div className="md:flex px-5 md:px-16 lg:px-32">
                <div className="mb-8 md:mb-0 md:flex-1">
                    <img src={learningMaterial} alt="Learning Material" className="object-contain" />
                </div>
                <div className="md:flex-1 md:pl-8 text-lg md:flex md:flex-col md:justify-center">
                    <p className="text-center md:text-left tracking-wide leading-relaxed mb-8">
                        Tim pendidikan anak usia dini Little Studycle sudah menyusun materi yang menargetkan aspek pembelajaran anak secara inklusif dalam bentuk aktivitas yang seru dan menyenangkan.
                    </p>
                    <p className="text-center md:text-left tracking-wide leading-relaxed mb-8">
                        Self-learning kit ini akan dikirimkan setiap bulannya dan dapat digunakan sebagai sarana berlatih anak, tidak hanya untuk mengembangkan kemampuan motorik mereka, tetapi juga kemampuan kognitif, linguistik, dan berbagai aspek kemampuan lainnya.
                    </p>
                    <p className="text-center md:text-left tracking-wide leading-relaxed mb-8">
                        Untuk Moms and Dads, don't worry, dalam setiap box ada panduan untuk setiap aktivitasnya kok, jadi bersiap untuk have fun bersama-sama ya!
                    </p>
                </div>
                
            </div>
        </section>
		);
	}

export default SectionMaterial;
	
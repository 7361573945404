import zigzag from "assets/img/zigzag.png";
import monkey from "assets/img/monkey.png";


const SectionSchedule = () => {
    const classData = [
        {
            name: 'Self-Learning',
            fee: {  
                usual: '300.000,-',
                promo: '150.000,-',
            }
        },
        {
            name: 'Online Class',
            fee: {
                registration: '100.000,-',
                usual: '500.000,-',
                promo: '375.000,-',
            }
        }
    ]
	return (
        <section className="flex flex-col relative items-center px-5 py-5 w-full mt-16">
            <img src={zigzag} className="absolute -top-8 md:top-12 lg:top-8 left-8 lg:left-24 h-8 lg:h-12" alt="" />
            <img src={monkey} className="absolute -top-24 md:-top-20 lg:-top-24 -right-8 lg:-right-12 h-32 md:h-48 lg:h-56" alt="" />
            <div className="flex items-center mb-8">
                <h2 className="text-5xl text-ls-pink-dark text-center leading-relaxed">
                    Ayo Belajar Bersama!
                </h2>
            </div>
            
            <div className="container mx-auto px-4 md:px-12">
                <div className="flex justify-center flex-wrap -mx-1 lg:-mx-4">
                    {
                        classData.map((classInfo, idx) => 
                            <div key={idx} className="my-8 px-1 w-full md:w-1/2 lg:w-1/3 lg:my-4 lg:px-4 min-h-full">
                                <article className="flex flex-col overflow-hidden rounded-lg shadow-lg h-full">
                                    <div className="flex-1 flex flex-col items-center justify-start leading-tight py-8 px-2 md:px-4 md:py-8 bg-ls-yellow text-ls-red-blood">
                                        <span className="text-3xl font-light mb-8">
                                            { classInfo.name }
                                        </span>
                                        {
                                            classInfo.fee.registration &&
                                            <span className="font-bold mb-2">
                                                Registration Fee: Rp. {classInfo.fee.registration}
                                            </span>
                                        }
                                        <span className="flex-1 font-bold flex items-center justify-center">
                                            Usual Monthly Fee: Rp.&nbsp;<span className="line-through">{classInfo.fee.usual}</span>
                                        </span>
                                    </div>

                                    
                                    <div className="flex flex-col items-center justify-between leading-none p-2 md:p-4 bg-ls-orange-dark">
                                        <p className="w-full text-white text-xl mb-2 font-light text-center tracking-wider">
                                            Promo Price
                                        </p>
                                        <p className="w-full text-white text-2xl font-light text-center tracking-wider">
                                            Rp. {classInfo.fee.promo}/month
                                        </p>
                                    </div>

                                </article>
                            </div>
                        )
                    }
                </div>
            </div>
        </section>
		);
	}

export default SectionSchedule;
	
import science from "assets/img/science.png";
import eng from "assets/img/eng.png";
import tech from "assets/img/tech.png";
import art from "assets/img/art.png";
import math from "assets/img/math.png";
import charYellow from 'assets/img/char-yellow.svg';
import charBlue from 'assets/img/dolphin.png';
import donut from 'assets/img/donut.png';
import triangle from 'assets/img/triangle.png';

const SectionSTEAM = () => {
	return (
        <section className="relative flex flex-col items-center px-5 py-20 w-full bg-ls-pink">
            <img src={charYellow} alt="" className="absolute -left-8 lg:-left-16 -top-8 lg:-top-16 h-32 lg:h-64" />
            <img src={charBlue} alt="" className="absolute -right-8 lg:-right-16 -bottom-8 lg:-bottom-16 h-32 lg:h-64" />
            <img src={donut} alt="" className="absolute -left-14 lg:-left-20 -bottom-14 lg:-bottom-20 h-28 lg:h-40" />
            <img src={triangle} alt="" className="absolute right-1/3 lg:right-1/4 -bottom-6 lg:-bottom-8 h-16 lg:h-20" />


            <h2 className="text-5xl text-ls-pink-dark mb-8 text-center">
                Pendidikan Berbasis STEAM
            </h2>
            <p className="md:max-w-3xl text-center px-2 md:px-8 text-lg tracking-wide leading-relaxed">
                Selain mengacu pada prinsip utama bermain beserta pokok kegiatan belajar anak usia dini, Little Studycle juga mendorong anak pada pengenalan akan science, technology, engineering, arts, & mathematics (STEAM) secara interaktif agar anak dapat memaksimalkan minat dan potensi mereka sejak dini.
            </p>
            <div className="w-full p-4 md:p-8 md:px-0 md:py-10 md:flex md:justify-center">
                <ul className="flex flex-wrap justify-center">
                    <li className="flex bg-ls-pink-light rounded-lg h-20 w-20 md:h-32 md:w-32 m-2 p-4">
                        <img src={science} alt="Science" className="object-contain" />
                    </li>
                    <li className="flex bg-ls-pink-light rounded-lg h-20 w-20 md:h-32 md:w-32 m-2 p-4">
                        <img src={tech} alt="Technology" className="object-contain" />
                    </li>
                    <li className="flex bg-ls-pink-light rounded-lg h-20 w-20 md:h-32 md:w-32 m-2 p-4">
                        <img src={eng} alt="Engineering" className="object-contain" />
                    </li>
                    <li className="flex bg-ls-pink-light rounded-lg h-20 w-20 md:h-32 md:w-32 m-2 p-4">
                        <img src={art} alt="Art" className="object-contain" />
                    </li>
                    <li className="flex bg-ls-pink-light rounded-lg h-20 w-20 md:h-32 md:w-32 m-2 p-4">
                        <img src={math} alt="Math" className="object-contain" />
                    </li>
                </ul>
            </div> 
        </section>
		);
	}

export default SectionSTEAM;
	
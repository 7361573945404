import { useState } from 'react';
import { TextInput } from "components/common/input";

const SectionForm = () => {
    const [regForm, setRegForm] = useState({
        name: '',
        parentName: '',
        phone: '',
        targetClass: -1,
        schedule: -1,
        info: '',
    })

    const onFormChange = (e) => {
        setRegForm({
            ...regForm,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }
    
	return (
        <section id="registration" className="flex flex-col items-center px-5 pt-20 pb-52 w-full bg-ls-pink">
            <h2 className="text-5xl text-ls-pink-dark mb-8 text-center">
                Pendaftaran
            </h2>
            <p className="mb-12 md:max-w-3xl text-center px-8 text-lg tracking-wide leading-relaxed">
                Kelas Little Studycle akan dimulai pada tanggal 1 Februari 2021, dan akan diadakan selama 4 bulan (kelas terakhir akan berlangsung pada 21 Mei 2021).
            </p>
            {
                /*
            <div className="w-full p-8 md:px-0 md:py-10 md:flex md:justify-center">
                <form className="w-full md:w-1/2 flex flex-col" onSubmit={handleSubmit}>
                    <TextInput
                        name="name"
                        label="1. Nama Anak"
                        placeholder="Nama anak"
                        value={regForm.name}
                        onChange={onFormChange}
                    />
                    <TextInput
                        name="parentName"
                        label="2. Nama Orangtua"
                        placeholder="Nama orang tua"
                        value={regForm.parentName}
                        onChange={onFormChange}
                    />
                    <TextInput
                        name="phone"
                        label="3. Nomor telepon/WA"
                        placeholder="Nomor telepon"
                        value={regForm.phone}
                        onChange={onFormChange}
                    />
                    kelas
                    extra
                    <fieldset className="flex justify-center">
                        <button type="submit" className="btn-pill blue w-1/2 md:w-1/3">Daftar</button>
                    </fieldset>
                </form>
            </div> 
            */
            }
            <a 
                href="https://docs.google.com/forms/d/e/1FAIpQLSd1yuKXTBXM8Pi3jKT4veZxChVM6EbMuY1e6XmnfuEH7FJMLg/viewform" 
                target="_blank"
                rel="noreferrer noopener"
                className="btn-pill blue mb-16">
                Isi Form Pendaftaran
            </a>
        </section>
		);
	}

export default SectionForm;
	